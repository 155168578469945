export enum ElementType {
    Root,
    Step,
    Alert,
    Container,
    Checkbox,
    Date,
    Headline,
    MultiCheckbox,
    Number,
    ReplicatingContainer,
    Richtext,
    Radio,
    Select,
    Spacer,
    Table,
    Text,
    Time,
    IntroductionStep,
    SubmitStep,
    SummaryStep,
    Image,
    SubmittedStep,
    FileUpload,
}
